<template>
    <section class="pb-5 pt-5">
        <v-container grid-list-md light text-xs-center>
            <v-layout align-center row wrap>
                <v-flex xs12>
                    <div class="text-xs-center ma-5 pt-5 pb-5" align="center">
                        <div class="flex display-1 font-weight-light pb-3">
                            Cloud Based POS & Marketing Platform For businesses.
                        </div>
                        <div class="flex subheading font-weight-light">Y2POS stands as a robust enterprise-level POS system, offering multi-unit support, web and mobile apps, online ordering, push notifications, SMS messaging, delivery driver tracking, software customizations, loyalty programs, gift cards, and an array of additional features.</div>
                    </div>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Local Website Development Design" contain src="img/img/sumni-pos.png">
                        </v-img>
                        <v-card-title class="justify-center">Restaurant POS</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Manage your restaurant from anywhere in the world including locations, staff, schedules, vendors, customers, tables, online ordering and more.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Integrated Marketing POS System" contain src="img/img/push-notifications-pos-restaurant.png">
                        </v-img>
                        <v-card-title class="justify-center">Web & Mobile Apps</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Includes branded Web App, IOS App, and Android App that works across all devices. Includes integrated online ordering with built-in marketing tools.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Customer Engagement" contain src="img/img/web-apps-restaurant-mobile-ios-android.png">
                        </v-img>
                        <v-card-title class="justify-center">Integrated Marketing Tools</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Send push notifications, emails and SMS messages to your customer lists. Ensure your message is seen with marketing automation.
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </section>
</template>

<script>
    export default {
        name: "HelloTop"
    }
</script>
