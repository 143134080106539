<template>
    <section class="pb-5 pt-5">
        <v-container grid-list-md light text-xs-center>
            <v-layout align-center row wrap>
                <v-flex xs12>
                    <div class="text-xs-center ma-5 pt-5 pb-5" align="center">
                        <div class="flex display-1 font-weight-light pb-3">
                            Smart Point-Of-Sale Terminals
                        </div>
                        <div class="flex subheading font-weight-light ">
                            Smart Terminals That Are Built To Handle The Demands & Scales With Technology For Now And The Future
                        </div>
                    </div>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Local Website Development Design" contain src="img/img/hardware/t2-terminals.png">
                        </v-img>
                        <v-card-title class="flex justify-center">T2 w/ Printer</v-card-title>
                        <v-card-text class="flex grey--text  text--darken-2 font-weight-light">
                            Checkout Terminal w/ Built-in Display & Printer
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Integrated Marketing POS System" contain src="img/img/hardware/sumni-smarter-terminals.png">
                        </v-img>
                        <v-card-title class="justify-center">T2 w/ No Printer</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light ">
                            Checkout Terminal w/ Customer Display
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Integrated Marketing POS System" contain src="img/img/hardware/hardware-server.png">
                        </v-img>
                        <v-card-title class="justify-center">T2 Server Terminal</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light ">
                            Station Terminal w/ No Customer Display
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Customer Engagement" contain src="img/img/hardware/t2-mini.png">
                        </v-img>
                        <v-card-title class="justify-center">T2 Digital Terminal</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light ">
                            Online Ordering Terminal For Online Orders.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Local Website Development Design" contain src="img/img/hardware/v2pro_1.png">
                        </v-img>
                        <v-card-title class="justify-center">V2 Server Handheld</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light ">
                            Mobile Ordering Device w/ Printer
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Customer Engagement" contain src="img/img/hardware/m2_1.png">
                        </v-img>
                        <v-card-title class="justify-center">V2 Server Tablet</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light ">
                            Tablet Ordering Device w/ No Printer
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Local Website Development Design" contain src="img/img/hardware/yumapos-printer.png">
                        </v-img>
                        <v-card-title class="justify-center">V2 Receipt Printer</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light ">
                            Receipt Printer For Terminals
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Integrated Marketing POS System" contain src="img/img/hardware/blink-scanner.png">
                        </v-img>
                        <v-card-title class="justify-center">T2 Blink Scanner</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light ">
                            Scanner For Customer Rewards And Loyalty
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Customer Engagement" contain src="img/img/hardware/cash-drawer-pos.png">
                        </v-img>
                        <v-card-title class="justify-center">V2 Cash Drawer</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light ">
                            Cash Drawer For Customer Checkout
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </section>
</template>

<script>
    export default {
        name: "HelloThird.vue"
    }
</script>

<style scoped>

</style>
